<!-- 电子合同 -->
<template>
  <div>
    <iframe id="mapPage" width="100%" height="900px" frameborder="0" v-bind:src="keyUrl"></iframe>
  </div>
</template>

<script>
//引入axios 为了电子合同
import axios from 'axios';
// 获取下电子合同url
import Config from '@/config/setting'

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    // 父级id
    id: Number
  },
  components: { },
  data() {
    return {
      // 传递的数据
      opeions:{},
      // 要生成WEB嵌入界面的类型
      embedType:'',
      //   - CREATE_SEAL: 生成创建印章的嵌入页面
      // - CREATE_TEMPLATE：生成创建模板的嵌入页面
      // - MODIFY_TEMPLATE：生成修改模板的嵌入页面
      // - PREVIEW_TEMPLATE：生成预览模板的嵌入页面
      // - PREVIEW_FLOW：生成预览合同文档的嵌入页面
      // - PREVIEW_FLOW_DETAIL：生成预览合同详情的嵌入页面
      // - PREVIEW_SEAL_LIST：生成预览印章列表的嵌入页面
      // - PREVIEW_SEAL_DETAIL：生成预览印章详情的嵌入页面

      // 链接
      keyUrl:''

    };
  },

  created() {
    // 传递的参数
    // row:row
    // value: valu
    // orgOpenId: this.orgOpenId
    // openId: this.openI

    this.opeions = JSON.parse(this.$route.query.param);
    console.log('传递的数据',this.opeions);
    // 调用获取方法
    this.gettemplate();
  },

  mounted() {

  },

  methods: {
    // 获取模板数据
    gettemplate(){
      // 判断获取的类型
      if(this.opeions.value == '编辑'){
        // 传递的参数
        this.embedType = 'MODIFY_TEMPLATE';
        var data = {
          // 接口名
          name: 'ChannelCreateEmbedWebUrl',
          // 传递的数据
          data: {
            // agent: {
            //   // 企业id
            //   organizationOpenId: '2317490490',
            //   operator:{
            //     // 经办人id
            //     openId: '1492d94f-8b3a-484f-80ba-9ec705a52ee7'
            //   }
            // },
            agent: {
              // 企业id
              // organizationOpenId: this.opeions.orgOpenId,
              operator:{
                // 经办人id
                openId: this.opeions.openId,
              }
            },
            // 嵌入页面的类型
            embedType: this.embedType,
            // 模板id
            businessIds: this.opeions.row.templateId
          },
        }
      }else if(this.opeions.value == '预览'){
        this.embedType = 'PREVIEW_TEMPLATE';
        var data = {
          // 接口名
          name: 'ChannelCreateEmbedWebUrl',
          // 传递的数据
          data: {
            // agent: {
            //   // 企业id
            //   organizationOpenId: '2317490490',
            //   operator:{
            //     // 经办人id
            //     openId: '1492d94f-8b3a-484f-80ba-9ec705a52ee7'
            //   }
            // },
            agent: {
              // 企业id
              // organizationOpenId: this.opeions.orgOpenId,
              operator:{
                // 经办人id
                openId: this.opeions.openId,
              }
            },
            // 嵌入页面的类型
            embedType: this.embedType,
            // 模板id
            businessIds: this.opeions.row.templateId
          },
        }
      }else if(this.opeions.value == '创建'){
        this.embedType = 'CREATE_TEMPLATE';
        var data = {
          // 接口名
          name: 'ChannelCreateEmbedWebUrl',
          // 传递的数据
          data: {
            // agent: {
            //   // 企业id
            //   organizationOpenId: '2317490490',
            //   operator:{
            //     // 经办人id
            //     openId: '1492d94f-8b3a-484f-80ba-9ec705a52ee7'
            //   }
            // },
            agent: {
              // 企业id
              // organizationOpenId: this.opeions.orgOpenId,
              operator:{
                // 经办人id
                openId: this.opeions.openId,
              }
            },
            // 嵌入页面的类型
            embedType: this.embedType,
          },
        }
      }else if(this.opeions.value == '印章列表'){
        this.embedType = 'PREVIEW_SEAL_LIST';
        var data = {
          // 接口名
          name: 'ChannelCreateEmbedWebUrl',
          // 传递的数据
          data: {
            // agent: {
            //   // 企业id
            //   organizationOpenId: '2329691410',
            //   operator:{
            //     // 经办人id
            //     openId: '21d39e9f-6828-4485-9a4f-6872089eaec8'
            //   }
            // },
            agent: {
              // 企业id
              // organizationOpenId: this.opeions.orgOpenId,
              operator:{
                // 经办人id
                openId: this.opeions.openId,
              }
            },
            // 嵌入页面的类型
            embedType: this.embedType,
          },
        }
      }

      // 发起请求
      axios({
        // 请求方式
        method: 'POST',
        // 接口域名
        url: Config.contracturl,
        data: data
      }).then(res => {
        console.log(res)
        this.keyUrl = res.data.data.data.webUrl
      })
    },

  }


}
</script>

<style scoped>
</style>
